import { Box } from "@mui/material";
import {
  edsn_Marktrol,
  RegistrationCreateDto,
} from "../../../openapi/edsn-webapi";
import OrganizationUnits from "../OrganizationUnits";
import { useFormContext } from "react-hook-form";
import ProductSelectControl from "../ProductSelectControl";
import CSPDetails from "../CSPDetails";
import RecognitionDetails from "../RecognitionDetails";
import Ean13Control from "../Ean13Control";
import CreatePreferredPartyControl from "../CreatePreferredPartyControl";
import BSPDetails from "../BSPDetails";
import { InvoiceContactControl } from "../InvoiceContactControl";

const RegistrationStep = () => {
  const { watch } = useFormContext<RegistrationCreateDto>();
  const marketRole = watch("marketRole")!;

  const showProduct = [
    edsn_Marktrol.LVLEVERANCIER,
    edsn_Marktrol.PVPROGRAMMAVERANTWOORDELIJKE,
    edsn_Marktrol.MVMEETVERANTWOORDELIJKE,
    edsn_Marktrol.GDSEIGENAAR_GESLOTEN_DISTRIBUTIE_SYSTEEM,
  ].includes(marketRole);

  const showAdminParty = [
    edsn_Marktrol.LVLEVERANCIER,
    edsn_Marktrol.PVPROGRAMMAVERANTWOORDELIJKE,
  ].includes(marketRole);

  const showInvoiceContact = [
    edsn_Marktrol.LVLEVERANCIER,
    edsn_Marktrol.GDSEIGENAAR_GESLOTEN_DISTRIBUTIE_SYSTEEM,
  ].includes(marketRole);

  return (
    <Box>
      <Ean13Control autoChangeFirstOrganizationUnit />
      {showProduct && <ProductSelectControl />}
      {showAdminParty && <CreatePreferredPartyControl />}
      <CSPDetails />
      <BSPDetails />
      <RecognitionDetails />
      {showAdminParty && <OrganizationUnits isCreate />}
      {showInvoiceContact && <InvoiceContactControl />}
    </Box>
  );
};

export default RegistrationStep;
