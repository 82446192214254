import { Typography } from "@mui/material";
import { RegistrationCreateDto } from "../../openapi/edsn-webapi";
import { safePropertyMap } from "../../utils";
import { InputControl, Tooltip } from "../common";
import { FormattedMessage, useIntl } from "react-intl";

export const InvoiceContactControl = () => {
  const map = safePropertyMap<RegistrationCreateDto>();
  const intl = useIntl();

  return (
    <>
      <Typography variant="h6" color="secondary" display="inline">
        <FormattedMessage id={"invoiceContactTitle"} />
        <Tooltip title="invoiceContactInfo" />
      </Typography>
      <InputControl
        label="invoiceContactName"
        name={map("invoiceContacts.0.regardingName")}
      />
      <InputControl
        pattern={{
          value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
          message: intl.formatMessage({ id: "invalidEmailAddress" }),
        }}
        type="email"
        label="invoiceContactEmail"
        name={map("invoiceContacts.0.email")}
      />
    </>
  );
};
