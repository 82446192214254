import {
  edsn_Marktrol,
  MprService,
  RegistrationCreateDto,
} from "../../../openapi/edsn-webapi";
import { Form, OverlayLoader, withFormContext } from "../../common";
import { SubmitHandler, UseFormProps } from "react-hook-form";
import { useMutationWrapper } from "../../../hooks";
import RegistrationFormStepper from "./RegistrationFormStepper";
import { useNavigate } from "react-router-dom";
import useRegistration from "../useRegistration";
import { useEffect } from "react";

type RegistrationFormProps = {
  id?: string | null;
  token?: string | null;
};

const RegistrationForm = ({ id, token }: RegistrationFormProps) => {
  const isUpdate = id && token;
  const setIsUpdate = useRegistration((state) => state.setIsUpdate);
  const permissionDocumentGas = useRegistration(
    (state) => state.permissionDocumentGas
  );
  const permissionDocumentElectricity = useRegistration(
    (state) => state.permissionDocumentElectricity
  );
  const navigate = useNavigate();
  const navigateToSuccess = () => navigate("/registratieformulier/success");

  const { mutate: uploadPermissionDocument, isLoading: isLoadingFileUpload } =
    useMutationWrapper(
      ["postApiMprUpload"],
      (formData: { RegistrationId?: string; Files?: Array<Blob> }) =>
        MprService.postApiMprUpload({ formData }),
      { onSuccess: navigateToSuccess }
    );

  const { mutate, isLoading: isLoadingSubmit } = useMutationWrapper(
    ["submitMprCreate"],
    (requestBody: RegistrationCreateDto) => {
      if (isUpdate) {
        return MprService.putApiMprCreate({ id, token, requestBody });
      }
      return MprService.postApiMprCreate({ requestBody });
    },
    {
      onSuccess: (data) => {
        if (permissionDocumentGas || permissionDocumentElectricity) {
          uploadPermissionDocument({
            Files: [permissionDocumentGas, permissionDocumentElectricity]
              .filter((m) => !!m)
              .map((n) => n as File),
            RegistrationId: data.registrationId,
          });
        } else {
          navigateToSuccess();
        }
      },
    }
  );

  useEffect(() => {
    setIsUpdate(!!(id && token));
  }, [id, token]);

  const onSubmit: SubmitHandler<RegistrationCreateDto> = (data) => {
    const defaultKnowledgeArea = process.env.REACT_APP_DEFAULT_KNOWLEDGE_AREA;
    const isValidMarketRole =
      data.marketRole === edsn_Marktrol.LVLEVERANCIER ||
      data.marketRole ===
        edsn_Marktrol.GDSEIGENAAR_GESLOTEN_DISTRIBUTIE_SYSTEEM;

    const updatedInvoiceContacts = isValidMarketRole
      ? data.invoiceContacts
          ?.filter((contact) => contact.regardingName || contact.email)
          .map((contact) => ({
            ...contact,
            knowledgeArea: defaultKnowledgeArea,
          })) || []
      : [];

    mutate({ ...data, invoiceContacts: updatedInvoiceContacts });
  };

  return (
    <Form onSubmit={onSubmit}>
      <RegistrationFormStepper />
      {(isLoadingSubmit || isLoadingFileUpload) && <OverlayLoader />}
    </Form>
  );
};

export default withFormContext<
  UseFormProps<RegistrationCreateDto> & RegistrationFormProps,
  RegistrationCreateDto
>(RegistrationForm);
